(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("mobx"), require("mobxReact"), require("sbDataLayer"), require("sbInternalMsgBus"), require("sbJsExtends"), require("sbLocalizationLib"), require("sbRespBlockLib"));
	else if(typeof define === 'function' && define.amd)
		define("sb-betting-history", ["React", "ReactDOM", "mobx", "mobxReact", "sbDataLayer", "sbInternalMsgBus", "sbJsExtends", "sbLocalizationLib", "sbRespBlockLib"], factory);
	else if(typeof exports === 'object')
		exports["sb-betting-history"] = factory(require("React"), require("ReactDOM"), require("mobx"), require("mobxReact"), require("sbDataLayer"), require("sbInternalMsgBus"), require("sbJsExtends"), require("sbLocalizationLib"), require("sbRespBlockLib"));
	else
		root["sb-betting-history"] = factory(root["React"], root["ReactDOM"], root["mobx"], root["mobxReact"], root["sbDataLayer"], root["sbInternalMsgBus"], root["sbJsExtends"], root["sbLocalizationLib"], root["sbRespBlockLib"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__804__, __WEBPACK_EXTERNAL_MODULE__196__, __WEBPACK_EXTERNAL_MODULE__965__, __WEBPACK_EXTERNAL_MODULE__98__, __WEBPACK_EXTERNAL_MODULE__239__, __WEBPACK_EXTERNAL_MODULE__858__, __WEBPACK_EXTERNAL_MODULE__153__, __WEBPACK_EXTERNAL_MODULE__585__, __WEBPACK_EXTERNAL_MODULE__529__) {
return 